import { Form, Formik } from "formik";

import React, { Suspense, useCallback, useRef } from "react";
import { TrackJS } from "trackjs";
import { DigitalGiftCardCreditCardInputs } from "./DigitalGiftCardCreditCardInputs";
import { StyledFormWrapper } from "./StyledFormWrapper";
import { StyledSubheader } from "./StyledSubheader";
import { StyledSubmitButtonRow } from "./StyledSubmitButtonRow";
import { DigitalGiftCardPaymentError } from "components/digital_gift_cards/DigitalGiftCardPaymentError";
import type { DigitalGiftCardOrderForm } from "components/digital_gift_cards/order_form";
import {
  CCProcessorConfigurationMap,
  type SubmitHandler,
} from "components/digital_gift_cards/payment/CCProcessorConfigurationMap";
import Loader from "components/loader";
import { CreditCardProcessLoading } from "components/payment/CreditCardProcessLoading";

import { useAdyenDigitalGiftCardPaymentAccess } from "hooks/digital_gift_cards/payment/adyen/useAdyenDigitalGiftCardPaymentAccess";
import { useSubmitDigitalGiftCardPayment } from "hooks/digital_gift_cards/useSubmitDigitalGiftCardPayment";
import { useSessionId } from "hooks/shared/useSessionId";
import type {
  DigitalGiftCardMerchantInfo,
  ICreditCardPaymentInfo,
} from "types/credit_card";
import type { DigitalGiftCardFormValues } from "types/digital_gift_cards/DigitalGiftCardFormValues";
import type { DigitalGiftCardPaymentConfirmation } from "types/digital_gift_cards/DigitalGiftCardPaymentConfirmation";
import type { CCProcessor } from "types/payments/credit_card";

export type IDigitalGiftCardCreditCardFormProps = {
  merchantInfo: DigitalGiftCardMerchantInfo;
  paymentInfo: ICreditCardPaymentInfo;
  isSubmitDisabled?: boolean;
  includeEmailField?: boolean;
  includePhoneField?: boolean;
} & (
  | {
      isPreview?: false;
      orderFormValues?: DigitalGiftCardOrderForm;
      onPaymentSuccess: (
        confirmation: DigitalGiftCardPaymentConfirmation
      ) => void;
      onPaymentError: (error: string) => void;
      error?: string | null;
    }
  | {
      isPreview: true;
      orderFormValues?: never;
      onPaymentSuccess?: never;
      onPaymentError?: never;
      error?: never;
    }
);

export function DigitalGiftCardCreditCardForm({
  isPreview,
  merchantInfo,
  paymentInfo: { balanceDue },
  orderFormValues,
  includeEmailField,
  includePhoneField,
  isSubmitDisabled,
  onPaymentSuccess,
  onPaymentError,
  error,
}: IDigitalGiftCardCreditCardFormProps) {
  const { storePrettyUrl } = merchantInfo;
  const { data: isAdyenEnabled } = useAdyenDigitalGiftCardPaymentAccess(
    storePrettyUrl,
    {
      // If it is preview, it won't call the API endpoint, and make `isAdyenEnabled` false as default
      enabled: !isPreview,
      initialData: isPreview ? { enabled: false } : undefined,
    }
  );
  const ccProcessor: CCProcessor = isAdyenEnabled ? "adyen" : "firstData";
  const ccProcessorConfiguration = CCProcessorConfigurationMap[ccProcessor];

  const { mutateAsync: submitDigitalGiftCardPayment, data: paymentResponse } =
    useSubmitDigitalGiftCardPayment();
  const sessionId = useSessionId();

  const submitHandlerRef = useRef<SubmitHandler | null>(null);
  const handleSubmit = useCallback(
    async (formValues: DigitalGiftCardFormValues) => {
      if (isPreview) {
        return;
      }

      try {
        const submitHandler = submitHandlerRef.current;
        if (!submitHandler) {
          throw new Error("Unable to find the submit handler.");
        }

        if (!orderFormValues) {
          throw new Error("orderFormValues is required");
        }

        const { additionalPaymentData, confirmationModalData } =
          await submitHandler(formValues, orderFormValues);

        const response = await submitDigitalGiftCardPayment({
          total: balanceDue,
          sessionId,
          formInfo: orderFormValues,
          storePrettyUrl,
          additionalPaymentData,
        });

        if (ccProcessorConfiguration?.isCustomizedSubmitProcessing) {
          return;
        }
        onPaymentSuccess({
          paymentResponse: response,
          confirmationModalData,
          consumerEmail: response.consumerEmail,
          digitalGiftCards: response.digitalGiftCards,
        });
      } catch (ex) {
        const message: string | undefined = await (async () => {
          const errorResponse = (ex as { response?: Response }).response;
          if (errorResponse) {
            const body = await errorResponse.json();
            return body.message;
          }

          return (ex as Error).message;
        })();

        if (message) {
          TrackJS.track(message);
        }

        onPaymentError(
          "There was an error processing your request. Please check the form and try again."
        );
      }
    },
    [
      isPreview,
      orderFormValues,
      submitDigitalGiftCardPayment,
      balanceDue,
      sessionId,
      storePrettyUrl,
      ccProcessorConfiguration?.isCustomizedSubmitProcessing,
      onPaymentSuccess,
      onPaymentError,
    ]
  );

  if (isAdyenEnabled === undefined) {
    return <Loader />;
  }

  if (!ccProcessorConfiguration) {
    return null;
  }

  const {
    form: CreditCardFormImpl,
    ccInputsGridLayout,
    ccInputs,
    getInitialValues = values => values,
  } = ccProcessorConfiguration;

  return (
    <Formik<DigitalGiftCardFormValues>
      initialValues={getInitialValues({
        firstName: "",
        lastName: "",
        phone: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
      })}
      onSubmit={handleSubmit}
      validateOnBlur>
      {({ isSubmitting, isValid, dirty }) => (
        <>
          {isSubmitting && <CreditCardProcessLoading />}
          <Suspense fallback={<Loader />}>
            <CreditCardFormImpl
              merchantInfo={merchantInfo}
              submitHandlerRef={submitHandlerRef}
              onPaymentSuccess={onPaymentSuccess}
              paymentResponse={paymentResponse}>
              <Form translate="yes">
                <StyledFormWrapper
                  includeEmailField={includeEmailField}
                  includePhoneField={includePhoneField}
                  ccInputsGridLayout={ccInputsGridLayout}>
                  <StyledSubheader>Credit Card</StyledSubheader>
                  <DigitalGiftCardCreditCardInputs
                    CCInputsComponent={ccInputs}
                    includeEmailField={includeEmailField}
                    includePhoneField={includePhoneField}
                  />
                  <div
                    className="upserve-dgc-terms"
                    style={{ gridArea: "terms" }}>
                    <p>
                      By clicking on “Submit Order” you’ll agree to the
                      <br />
                      <a
                        href="https://assets-upserve.s3.amazonaws.com/terms-and-conditions/digital-gift-cards-terms-and-conditions.html"
                        rel="noopener noreferrer"
                        target="_blank">
                        Terms and Conditions
                      </a>
                      , and the credit card above will be charged.
                    </p>
                  </div>

                  <StyledSubmitButtonRow>
                    {error && <DigitalGiftCardPaymentError error={error} />}
                    <button
                      type="submit"
                      disabled={
                        isSubmitDisabled || !isValid || isSubmitting || !dirty
                      }>
                      Submit Order ({`${balanceDue.format()}`})
                    </button>
                  </StyledSubmitButtonRow>
                </StyledFormWrapper>
              </Form>
            </CreditCardFormImpl>
          </Suspense>
        </>
      )}
    </Formik>
  );
}
